import React from "react";
import { IoSearch, IoCreate, IoPaperPlane } from "react-icons/io5";

import Image from "../../../components/image";
import { Container, Wrapper, Row, Box } from "../../../components/util";
import {
  CallToAction,
  FeaturePageHeader,
} from "../../../components/site";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";

const Messaging = (props) => (
  <Layout>
    <Seo
      title="Smart Reminder Messaging | Karmly Feature"
      description="View timesheet status at a glance, send bulk reminder emails from saved templates & chase up overdue timesheets & approvals with Karmly messaging."
      pathname={props.location.pathname}
    />
    <FeaturePageHeader
      title="Smart Reminder Messaging"
      description="Chasing up overdue timesheets and approvals doesn’t need to take hours. View timesheet status at a glance and send bulk reminder emails from saved templates to keep things moving."
      image="InvoxyFeature_SmartMessaging.png"
    />
    <Container>
      <Wrapper>
        <Row className="-textCenter" stackGap={40}>
          <Box size={33}>
            <h3>
              <IoSearch />
              <br />
              Timesheet Status
            </h3>
            <p>See the status of every time entry created in Karmly.</p>
          </Box>
          <Box size={33}>
            <h3>
              <IoCreate />
              <br />
              Email Templates
            </h3>
            <p>
              Save templates with your branding and wording to send in different
              scenarios.
            </p>
          </Box>
          <Box size={33}>
            <h3>
              <IoPaperPlane />
              <br />
              Bulk Reminders
            </h3>
            <p>
              Filter and select a group of people or schedule automated reminder
              messages.
            </p>
          </Box>
        </Row>
        <hr />
        <Row stackGap={60} alignCenter mobileReverse>
          <Box size={50}>
            <Image
              filename="InvoxyFeature_Messaging_ApproveTime.png"
              alt="Time Approval"
            />
          </Box>
          <Box size={50}>
            <h3>Keep Approvals Moving</h3>
            <p>
              The faster your contractor timesheets are approved the faster you
              get paid. With Karmly you can see at a glance where every
              timesheet is in the approval process, including those which are
              yet to be submitted.
            </p>
          </Box>
        </Row>
        <Row stackGap={60} alignCenter>
          <Box size={50}>
            <h3>Let Karmly Do The Chasing</h3>
            <p>
              Schedule your reminder messages daily, weekly or monthly to chase
              up overdue timesheets and approvals. Choose the frequency of
              reminders and the time of day you want them to be sent and let
              Karmly automate the process of getting timesheets submitted and
              approved, ready for payroll and billing.
            </p>
          </Box>
          <Box size={50}>
            <Image
              filename="InvoxyFeature_MessageTemplate.png"
              alt="Custom Message Templates"
            />
          </Box>
        </Row>
        <Row stackGap={60} alignCenter mobileReverse>
          <Box size={50}>
            <Image
              filename="InvoxyFeature_Messaging_FilterTime.png"
              alt="Customise Time View"
            />
          </Box>
          <Box size={50}>
            <h3>Filter With Ease</h3>
            <p>
              Powerful filter and search functionality lets you drill down to
              see the status of any timesheet entry so you can select groups of
              people to email.
            </p>
          </Box>
        </Row>
        <Row stackGap={60} alignCenter>
          <Box size={50}>
            <h3>Personalise Your Emails</h3>
            <p>
              Use placeholders to populate email templates and personalise the
              messages that you send to contractors and clients, even when
              they’re sent in bulk.
            </p>
          </Box>
          <Box size={50}>
            <Image
              filename="InvoxyFeature_Messaging_Personal-1.png"
              alt="Peronalise Emails"
            />
          </Box>
        </Row>
        <Row stackGap={60} alignCenter mobileReverse>
          <Box size={50}>
            <Image
              filename="InvoxyFeature_MessageSent.png"
              alt="Historical Messages"
            />
          </Box>
          <Box size={50}>
            <h3>Keep Track of Messages</h3>
            <p>
              See every message sent from Karmly and whether it has been
              delivered, opened or clicked so you can easily manage queries from
              contractors and approving managers.
            </p>
          </Box>
        </Row>
      </Wrapper>
    </Container>
    <CallToAction />
  </Layout>
);

export default Messaging;
